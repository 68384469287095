<template>
  <div>
    <v-container
      fluid
      tile
      class="pa-0"
      :style="
        'z-index: 500 !important; background-color: ' +
          this.webTheme.colors.canvas.hexa
      "
    >
      <v-row justify="center" no-gutters>
        <v-col cols="12" :sm="component.settings.cols">
          <v-card
            elevation="0"
            :height="height"
            tile
            :color="component.settings.color.hexa"
            :img="
              component.settings.mode === 'image'
                ? component.background.image
                : ''
            "
          >
            <v-row
              no-gutters
              :justify="component.block.justify"
              :align="component.block.align"
              class="fill-height"
            >
              <v-col xs="12" sm="10" md="8" :lg="component.block.cols">
                <v-col>
                  <v-card
                    :class="classes"
                    :style="styles + ';' + gradient"
                    :elevation="component.card.elevation"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto"
                        v-if="
                          component.logo.placement === 'left' &&
                            component.logo.image
                        "
                      >
                        <view-logo
                          :style="logoMargin"
                          :settings="component.logo"
                        />
                      </v-col>
                      <v-col>
                        <view-logo
                          :style="logoMargin"
                          v-if="
                            component.logo.placement === 'top' &&
                              component.logo.image
                          "
                          :settings="component.logo"
                        />
                        <view-text :settings="component.title"></view-text>
                        <view-text :settings="component.subtitle"></view-text>
                        <template v-if="registrationSuccess">
                          <div>
                            <v-row
                              no-gutters
                              class="px-8 pb-9"
                              justify="center"
                            >
                              <v-icon
                                size="100"
                                class="pt-9 pb-12"
                                :color="
                                  this.$store.getters.currentProgram.webTheme
                                    .colors.primary.hex
                                "
                                >fa-regular fa-circle-check</v-icon
                              >
                              <view-text
                                :settings="component.stepperLabels"
                                :value="
                                  'Your registration has been submitted and its approval is pending. You will receive an email upon approval.'
                                "
                              ></view-text>
                            </v-row>
                          </div>
                        </template>
                        <v-stepper
                          v-else
                          flat
                          class="stepper-style mb-n6"
                          v-model="stage"
                          vertical
                          :color="component.button.color.hex"
                        >
                          <!---------------------- Contact Details ------------------------->
                          <v-stepper-step
                            step="1"
                            :complete="stage > 1"
                            :color="component.button.color.hex"
                          >
                            <v-row class="ml-0"
                              ><view-text
                                :settings="component.stepperLabels"
                                value="Contact Details"
                              ></view-text
                            ></v-row>
                            <v-row v-if="stage > 1" class="ml-0"
                              ><view-text
                                :settings="component.stepperSummary"
                                :value="
                                  firstname + ' ' + lastname + ' ' + email
                                "
                              ></view-text
                            ></v-row>
                            <v-row
                              v-if="stage > 1 && phoneAtRegistration && phone"
                              class="ml-0"
                              ><view-text
                                :settings="component.stepperSummary"
                                value="Phone"
                                :suffix="phone"
                              ></view-text
                            ></v-row>
                            <v-row
                              v-if="stage > 1 && mobileAtRegistration && mobile"
                              class="ml-0"
                              ><view-text
                                :settings="component.stepperSummary"
                                value="Mobile"
                                :suffix="mobile"
                              ></view-text
                            ></v-row>
                            <v-row
                              v-if="
                                stage > 1 &&
                                  memberDataKeyAtRegistration &&
                                  memberDataKey
                              "
                              class="ml-0"
                              ><view-text
                                :settings="component.stepperSummary"
                                :value="
                                  memberDataKeyLabel + ': ' + memberDataKey
                                "
                              ></view-text
                            ></v-row>
                          </v-stepper-step>
                          <v-stepper-content step="1">
                            <v-form ref="contact">
                              <v-row no-gutters class="mt-1">
                                <v-text-field
                                  v-model="firstname"
                                  id="firstname"
                                  name="firstname"
                                  :label="$t('First name')"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :rules="rules.firstname"
                                  required
                                  key="member.firstname"
                                />
                              </v-row>
                              <v-row no-gutters>
                                <v-text-field
                                  v-model="lastname"
                                  id="lastname"
                                  name="lastname"
                                  :label="$t('Last name')"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :rules="rules.lastname"
                                  required
                                  key="member.lastname"
                                />
                              </v-row>
                              <v-row no-gutters>
                                <v-text-field
                                  v-model="email"
                                  id="email"
                                  name="email"
                                  :label="$t('Email')"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :rules="rules.email"
                                  required
                                  key="member.email"
                                />
                              </v-row>
                              <v-row no-gutters>
                                <v-text-field
                                  v-if="phoneAtRegistration"
                                  v-model="phone"
                                  id="phone"
                                  name="phone"
                                  :label="$t('Phone')"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :rules="
                                    this.phoneAtRegistrationRequired
                                      ? rules.phone
                                      : []
                                  "
                                  :disabled="!registrationOpen"
                                  :required="this.phoneAtRegistrationRequired"
                                  key="member.phone"
                                />
                              </v-row>
                              <v-row no-gutters>
                                <v-text-field
                                  v-if="mobileAtRegistration"
                                  v-model="mobile"
                                  id="mobile"
                                  name="mobile"
                                  :label="$t('Mobile')"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :rules="
                                    this.mobileAtRegistrationRequired
                                      ? rules.mobile
                                      : []
                                  "
                                  :required="this.mobileAtRegistrationRequired"
                                  key="member.mobile"
                                />
                              </v-row>
                              <v-row
                                v-if="memberDataKeyAtRegistration"
                                no-gutters
                              >
                                <v-text-field
                                  v-model="memberDataKey"
                                  id="memberDataKey"
                                  name="memberDataKey"
                                  :label="memberDataKeyLabel"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :rules="
                                    this.memberDataKeyAtRegistrationRequired
                                      ? rules.memberDataKey
                                      : []
                                  "
                                  :required="
                                    this.memberDataKeyAtRegistrationRequired
                                  "
                                  key="member.memberDataKey"
                                />
                              </v-row>
                              <v-row
                                v-if="memberDataKeyAtRegistration"
                                no-gutters
                                class="mt-n3 ml-0 mb-3"
                              >
                                <view-text
                                  :settings="component.stepperSummary"
                                  :value="memberDataKeyDescription"
                                ></view-text>
                              </v-row>
                              <v-row no-gutters>
                                <v-btn
                                  v-if="registrationOpen"
                                  class="ml-0"
                                  :color="component.button.color.hex"
                                  @click="nextStep()"
                                  elevation="0"
                                  :width="component.button.width"
                                  :height="component.button.height"
                                  :dark="!component.button.dark"
                                >
                                  {{ $t("NEXT") }}
                                </v-btn>
                                <v-btn
                                  v-else
                                  type="submit"
                                  :color="component.button.color.hexa"
                                  :block="component.button.block"
                                  disabled
                                  class="ma-0"
                                  elevation="0"
                                  :height="component.button.height"
                                  :loading="loading"
                                >
                                  {{ $t("REGISTRATION CLOSED") }}
                                </v-btn>
                              </v-row>
                            </v-form>
                          </v-stepper-content>

                          <!--------------------- Company -------------------------->
                          <v-stepper-step
                            :step="step.company || ''"
                            v-if="showCompanyStep"
                            :complete="stage > step.company"
                            :color="component.button.color.hex"
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  value="Company"
                                ></view-text
                              ></v-row>

                              <v-row
                                v-if="
                                  stage > step.company &&
                                    !addCompanyAtRegistration
                                "
                                class="ml-0"
                              >
                                <view-text
                                  v-for="company in getCompanyName(
                                    selectedCompanies
                                  )"
                                  :key="company"
                                  :settings="component.stepperSummary"
                                  :value="company"
                                ></view-text>
                              </v-row>
                              <v-row
                                v-if="
                                  stage > step.company &&
                                    addCompanyAtRegistration
                                "
                                class="ml-0"
                              >
                                <view-text
                                  :settings="component.stepperSummary"
                                  :value="newCompanyObj.title"
                                ></view-text>
                              </v-row>
                              <v-row
                                v-if="
                                  stage > step.company &&
                                    companyDataKeyAtRegistration &&
                                    !addCompanyAtRegistration &&
                                    newCompanyObj.accountKey
                                "
                                class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="
                                    companyDataKeyLabel + ': ' + companyDataKey
                                  "
                                ></view-text
                              ></v-row>
                              <v-row
                                v-if="
                                  stage > step.company &&
                                    companyDataKeyAtRegistration &&
                                    addCompanyAtRegistration &&
                                    newCompanyObj.accountKey
                                "
                                class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="
                                    companyDataKeyLabel +
                                      ': ' +
                                      (newCompanyObj.accountKey
                                        ? newCompanyObj.accountKey
                                        : 'Not Provided')
                                  "
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>

                          <v-stepper-content
                            :step="step.company || ''"
                            v-if="showCompanyStep"
                          >
                            <v-form ref="company">
                              <v-row
                                v-if="joinCompanyAtRegistration"
                                no-gutters
                              >
                                <v-select
                                  v-if="companyRegistrationGroupsExist"
                                  v-model="selectedCompanyTag"
                                  :items="companyRegistrationGroups"
                                  item-text="tag"
                                  item-value="id"
                                  :label="companyRegistrationGroupLabel"
                                  background-color="white"
                                  dense
                                  outlined
                                  filled
                                  clearable
                                  :color="fieldBorder"
                                  @change="clearSelectedCompany()"
                                  :disabled="addNewCompany"
                                  class="mt-1"
                                />
                              </v-row>
                              <v-row
                                v-if="joinCompanyAtRegistration"
                                no-gutters
                              >
                                <v-select
                                  :disabled="
                                    storeSelectDisabled || addNewCompany
                                  "
                                  v-model="selectedCompanies"
                                  :items="companies"
                                  item-text="title"
                                  item-value="id"
                                  id="selectedCompany"
                                  name="selectedCompany"
                                  :label="companyAtRegistrationLabel"
                                  background-color="white"
                                  dense
                                  outlined
                                  filled
                                  clearable
                                  @click:clear="clearSelectedCompany()"
                                  :color="fieldBorder"
                                  :no-data-text="$t('No companies found')"
                                  :multiple="multipleCompaniesAtRegistration"
                                  :required="
                                    this.companyAtRegistrationRequired &&
                                      !this.addNewCompany
                                  "
                                  :rules="
                                    this.companyAtRegistrationRequired
                                      ? rules.selectedCompany
                                      : []
                                  "
                                  key="company.selectedCompany"
                                />
                              </v-row>
                              <v-row
                                v-if="
                                  joinCompanyAtRegistration &&
                                    addCompanyAtRegistration
                                "
                                no-gutters
                                class="pb-6"
                                align="center"
                                justify="start"
                              >
                                <view-text
                                  v-if="!addNewCompany"
                                  :settings="component.stepperLabels"
                                  value="Cannot find your company?"
                                ></view-text>
                                <view-text
                                  v-if="addNewCompany"
                                  :settings="component.stepperLabels"
                                  value="Add New Company"
                                ></view-text>
                                <v-btn
                                  small
                                  elevation="0"
                                  :disabled="addNewCompany"
                                  :color="component.button.color.hex"
                                  :dark="!component.button.dark"
                                  @click="handleAddNewCompany()"
                                  >{{ $t("ADD NEW COMPANY") }}</v-btn
                                >
                                <v-btn
                                  small
                                  elevation="0"
                                  v-if="addNewCompany"
                                  :color="component.button.color.hex"
                                  :dark="!component.button.dark"
                                  @click="handleCloseAddNewCompany()"
                                  >{{ $t("CANCEL") }}</v-btn
                                >
                              </v-row>
                              <v-row
                                v-if="
                                  (addCompanyAtRegistration &&
                                    !joinCompanyAtRegistration) ||
                                    (addCompanyAtRegistration && addNewCompany)
                                "
                                no-gutters
                              >
                                <v-text-field
                                  v-model="newCompanyObj.title"
                                  id="title"
                                  name="title"
                                  :label="$t('Company Name')"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :required="this.companyAtRegistrationRequired"
                                  :rules="
                                    this.companyAtRegistrationRequired
                                      ? rules.title
                                      : []
                                  "
                                  key="company.title"
                                />
                              </v-row>
                              <v-row
                                v-if="
                                  (addCompanyAtRegistration &&
                                    !joinCompanyAtRegistration) ||
                                    (addCompanyAtRegistration && addNewCompany)
                                "
                                no-gutters
                              >
                                <v-text-field
                                  v-model="newCompanyObj.accountKey"
                                  id="companyDataKey"
                                  name="companyDataKey"
                                  :label="companyDataKeyLabel"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  :disabled="!registrationOpen"
                                  :required="
                                    this.companyDataKeyAtRegistrationRequired
                                  "
                                  :rules="
                                    this.companyDataKeyAtRegistrationRequired
                                      ? rules.companyDataKey
                                      : []
                                  "
                                  key="company.companyDataKey"
                                />
                              </v-row>
                              <v-row
                                v-if="
                                  (addCompanyAtRegistration &&
                                    !joinCompanyAtRegistration) ||
                                    (addCompanyAtRegistration && addNewCompany)
                                "
                                no-gutters
                                class="mt-n2 ml-1 mb-3"
                              >
                                <view-text
                                  :settings="component.stepperSummary"
                                  :value="companyDataKeyDescription"
                                ></view-text>
                              </v-row>
                              <v-row no-gutters>
                                <v-btn
                                  class="ml-0"
                                  :color="component.button.color.hex"
                                  @click="nextStep()"
                                  elevation="0"
                                  :disabled="companyNextButtonActive"
                                  :width="component.button.width"
                                  :dark="!component.button.dark"
                                  :height="component.button.height"
                                >
                                  {{ $t("NEXT") }}
                                </v-btn>
                                <v-btn
                                  :color="component.button.color.hex"
                                  @click="backStep()"
                                  elevation="0"
                                  text
                                >
                                  {{ $t("BACK") }}
                                </v-btn>
                              </v-row>
                            </v-form>
                          </v-stepper-content>

                          <!---------------------- Photo ID ------------------------->
                          <v-stepper-step
                            :step="step.photo || ''"
                            v-if="memberPhotoIdAtRegistration"
                            :complete="stage > step.photo"
                            :color="component.button.color.hex"
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  :value="memberPhotoIdLabel"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > step.photo" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="photoIdFile.name"
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content
                            :step="step.photo || ''"
                            v-if="memberPhotoIdAtRegistration"
                          >
                            <v-form ref="photo">
                              <v-row no-gutters>
                                <v-file-input
                                  v-model="photoIdFile"
                                  dense
                                  outlined
                                  truncate-length="20"
                                  background-color="white"
                                  :color="fieldBorder"
                                  filled
                                  clearable
                                  :required="
                                    this.memberPhotoIdAtRegistrationRequired
                                  "
                                  :rules="
                                    this.memberPhotoIdAtRegistrationRequired
                                      ? rules.photo
                                      : []
                                  "
                                ></v-file-input>
                              </v-row>
                              <v-row no-gutters class="mt-n1 ml-0 mb-3">
                                <view-text
                                  :settings="component.stepperSummary"
                                  :value="memberPhotoIdDescription"
                                ></view-text>
                              </v-row>
                              <v-row no-gutters>
                                <v-btn
                                  class="ml-0"
                                  :color="component.button.color.hex"
                                  :dark="!component.button.dark"
                                  @click="nextStep()"
                                  elevation="0"
                                  :width="component.button.width"
                                  :height="component.button.height"
                                >
                                  {{ $t("NEXT") }}
                                </v-btn>
                                <v-btn
                                  :color="component.button.color.hex"
                                  @click="backStep()"
                                  elevation="0"
                                  text
                                >
                                  {{ $t("BACK") }}
                                </v-btn>
                              </v-row>
                            </v-form>
                          </v-stepper-content>

                          <!------------------------  Review  ----------------------->
                          <v-stepper-step
                            :step="step.review || ''"
                            :complete="stage > step.review"
                            :color="component.button.color.hex"
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  value="Review"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > step.review - 1" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  value="Ready to submit?"
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content
                            :step="step.review || ''"
                            class="mb-0"
                          >
                            <v-btn
                              :color="component.button.color.hex"
                              :dark="!component.button.dark"
                              :height="component.button.height"
                              :block="component.button.block"
                              class="ml-0"
                              elevation="0"
                              @click="handleSignUp()"
                              :loading="loading"
                            >
                              {{ $t("Ready to submit?") }}
                            </v-btn>
                            <v-btn
                              :color="component.button.color.hex"
                              :dark="!component.button.dark"
                              @click="backStep()"
                              elevation="0"
                              :text="!registrationErrors.length > 0"
                              :disabled="loading"
                              :width="component.button.width"
                              :height="component.button.height"
                            >
                              {{ $t("BACK") }}
                            </v-btn>
                          </v-stepper-content>

                          <!----------------------------------------------->
                        </v-stepper>

                        <div>
                          <view-text
                            :settings="component.already"
                            :value="component.value"
                          ></view-text>
                          <a @click="handleSignin()"
                            ><view-text
                              :settings="component.signinLink"
                              :value="component.value"
                            ></view-text
                          ></a>

                          <view-text
                            :settings="component.signing"
                            :value="component.value"
                          ></view-text>
                          <a @click="handleTermsDialog()"
                            ><view-text
                              :settings="component.terms"
                              :value="component.value"
                            ></view-text
                          ></a>
                        </div>

                        <view-logo
                          :style="logoMargin"
                          v-if="
                            component.logo.placement === 'bottom' &&
                              component.logo.image
                          "
                          :settings="component.logo"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-if="
                          component.logo.placement === 'right' &&
                            component.logo.image
                        "
                      >
                        <view-logo
                          :style="logoMargin"
                          :settings="component.logo"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="
                        registrationErrors.length > 0 || errors.items.length > 0
                      "
                      no-gutters
                      class="mt-n9 mx-5"
                      align="center"
                    >
                      <v-col>
                        <v-alert
                          :color="component.button.color.hex"
                          :class="
                            component.button.dark
                              ? 'black--text alert-text pl-7'
                              : 'white--text alert-text pl-7'
                          "
                        >
                          <v-icon
                            class="ml-n2 mr-2"
                            :color="component.button.dark ? 'black' : 'white'"
                            >warning</v-icon
                          >

                          {{ $t("There is a problem with your registration") }}

                          <template>
                            <ul>
                              <li
                                v-for="(error, i) in registrationErrors"
                                :key="i"
                              >
                                <span>{{ $t(error) }}</span>
                              </li>
                            </ul>
                          </template>

                          <template>
                            <ul>
                              <li v-for="(error, i) in errors.items" :key="i">
                                <span>{{ error.msg }}</span>
                              </li>
                            </ul>
                          </template>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <Terms
          :termsDialogOpen="openTermsDialog"
          @onClose="onCloseTerms"
          :component="component"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ViewLogo from "../Logo.vue";
import ViewText from "../Text.vue";
import Footnote from "../../../Footnote.vue";
import Terms from "./TermsDialog.vue";
import { AVATAR_COLORS } from "@/constants/colors";
export default {
  components: {
    ViewLogo,
    ViewText,
    Footnote,
    Terms
  },
  data() {
    return {
      validContact: true,
      validCompany: true,
      validPhoto: true,
      rules: {
        email: [
          v => !!v || this.$t("Email is required"),
          v =>
            /.+@.+\..+/.test(v) ||
            this.$t("The email field must be a valid email")
        ],
        firstname: [v => !!v || this.$t("First name is required")],
        lastname: [v => !!v || this.$t("Last name is required")],
        phone: [v => !!v || this.$t("Phone is required")],
        mobile: [v => !!v || this.$t("Mobile is required")],
        memberDataKey: [
          v => !!v || this.memberDataKeyLabel + " " + this.$t("is required")
        ],
        companyDataKey: [
          v => !!v || this.companyDataKeyLabel + " " + this.$t("is required")
        ],
        title: [v => !!v || this.$t("Company name is required")],
        selectedCompany: [
          v => (v && v.length > 0) || this.$t("Company is required")
        ],
        photo: [v => !!v || this.$t("Photo identification is required")]
      },
      stage: 1,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      phone: "",
      newCompanyObj: {},
      selectedCompanyTag: "",
      selectedCompanies: [],
      companyDataKey: "",
      memberDataKey: "",
      photoIdFile: null,
      fieldBorder: "#bdbdbd",
      openTermsDialog: false,
      addNewCompany: false,
      image: {
        name: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: ""
      }
    };
  },
  props: ["component", "index"],
  created() {
    this.$store.dispatch("setSnackbar", null);
    this.$store.dispatch("setAuthLoading", false);
    this.$store.dispatch("setRegistrationSuccess", false);
    this.$store.dispatch("setRegistrationErrors", []);
    this.$store.dispatch("setCurrentUser", null);
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadCompanies");
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  computed: {
    step() {
      const neither = { company: "", photo: "", review: 2 };
      const both = { company: 2, photo: 3, review: 4 };
      const company = { company: 2, photo: "", review: 3 };
      const photo = { company: "", photo: 2, review: 4 };
      if (this.showCompanyStep && this.memberPhotoIdAtRegistration) {
        return both;
      } else if (this.showCompanyStep && !this.memberPhotoIdAtRegistration) {
        return company;
      } else if (!this.showCompanyStep && this.memberPhotoIdAtRegistration) {
        return photo;
      }
      return neither;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    loading() {
      return this.$store.getters.authLoading;
    },
    registrationSuccess() {
      return this.$store.getters.registrationSuccess;
    },
    registrationErrors() {
      return this.$store.getters.registrationErrors;
    },
    companyNextButtonActive() {
      if (!this.addNewCompany) {
        if (
          this.selectedCompanies === Array &&
          this.selectedCompanies.length > 0
        ) {
          return false;
        } else if (this.selectedCompanies !== Array && this.selectedCompanies) {
          return false;
        } else if (!this.companyAtRegistrationRequired) {
          return false;
        }
        return true;
      } else {
        if (
          (this.newCompanyObj.title && this.newCompanyObj.accountKey) ||
          (this.newCompanyObj.title &&
            !this.companyDataKeyAtRegistrationRequired)
        ) {
          return false;
        }
        return true;
      }
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    companyRegistrationGroups() {
      const tags = this.$store.state.companytag.companyTags;
      const groups = this.$store.getters.currentProgram
        .companyRegistrationGroups;
      const objs = [];
      groups.forEach(id => {
        const val = tags.find(rec => rec.id === id);
        objs.push(val);
      });
      const final = objs.sort((a, b) =>
        a.tag > b.tag ? 1 : b.tag > a.tag ? -1 : 0
      );
      return final;
    },
    companyRegistrationGroupsExist() {
      return this.companyRegistrationGroups.length > 0;
    },
    companyRegistrationGroupLabel() {
      return this.$store.getters.currentProgram.companyRegistrationGroupLabel;
    },
    addCompanyAtRegistration() {
      return this.$store.getters.currentProgram.addCompanyAtRegistration;
    },
    companyAtRegistrationRequired() {
      return this.$store.getters.currentProgram.companyAtRegistrationRequired;
    },
    joinCompanyAtRegistration() {
      return this.$store.getters.currentProgram.joinCompanyAtRegistration;
    },
    companyAtRegistrationLabel() {
      return this.$store.getters.currentProgram.companyAtRegistrationLabel;
    },
    phoneAtRegistration() {
      return this.$store.getters.currentProgram.phoneAtRegistration;
    },
    phoneAtRegistrationRequired() {
      return this.$store.getters.currentProgram.phoneAtRegistrationRequired;
    },
    mobileAtRegistration() {
      return this.$store.getters.currentProgram.mobileAtRegistration;
    },
    mobileAtRegistrationRequired() {
      return this.$store.getters.currentProgram.mobileAtRegistrationRequired;
    },
    multipleCompaniesAtRegistration() {
      return this.$store.getters.currentProgram.multipleCompaniesAtRegistration;
    },
    memberDataKeyAtRegistration() {
      return this.$store.getters.currentProgram.memberDataKeyAtRegistration;
    },
    memberDataKeyAtRegistrationRequired() {
      return this.$store.getters.currentProgram
        .memberDataKeyAtRegistrationRequired;
    },
    memberDataKeyLabel() {
      return this.$store.getters.currentProgram.memberDataKeyLabel;
    },
    memberDataKeyDescription() {
      return this.$store.getters.currentProgram.memberDataKeyDescription;
    },
    memberPhotoIdAtRegistration() {
      return this.$store.getters.currentProgram.memberPhotoIdAtRegistration;
    },
    memberPhotoIdAtRegistrationRequired() {
      return this.$store.getters.currentProgram
        .memberPhotoIdAtRegistrationRequired;
    },
    memberPhotoIdLabel() {
      return this.$store.getters.currentProgram.memberPhotoIdLabel;
    },
    memberPhotoIdDescription() {
      return this.$store.getters.currentProgram.memberPhotoIdDescription;
    },
    companyDataKeyAtRegistration() {
      return this.$store.getters.currentProgram.companyDataKeyAtRegistration;
    },
    companyDataKeyAtRegistrationRequired() {
      return this.$store.getters.currentProgram
        .companyDataKeyAtRegistrationRequired;
    },
    companyDataKeyLabel() {
      return this.$store.getters.currentProgram.companyDataKeyLabel;
    },
    companyDataKeyDescription() {
      return this.$store.getters.currentProgram.companyDataKeyDescription;
    },
    storeSelectDisabled() {
      if (this.selectedCompanyTag) {
        return false;
      } else if (
        this.companyRegistrationGroupsExist &&
        this.selectedCompanyTag
      ) {
        return false;
      } else if (
        this.companyRegistrationGroupsExist &&
        !this.selectedCompanyTag
      ) {
        return true;
      }
      return false;
    },
    companies() {
      const companies = this.$store.state.company.companies;
      if (this.selectedCompanyTag) {
        return companies.filter(el =>
          el.tags.includes(this.selectedCompanyTag)
        );
      }
      return companies;
    },
    registrationOpen() {
      return this.$store.getters.currentProgram.registrationOpen || false;
    },
    showCompanyStep() {
      return (
        this.$store.getters.currentProgram.joinCompanyAtRegistration ||
        this.$store.getters.currentProgram.addCompanyAtRegistration
      );
    },
    loading() {
      return this.$store.getters.authLoading;
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: " +
        this.component.card.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: " +
        this.component.card.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    classes() {
      var classes = this.component.card.rounded;
      return classes;
    },
    grid() {
      return this.$store.getters.grid;
    },
    logoMargin() {
      const val =
        "margin-bottom: " +
        this.component.logo.marginBottom +
        "px ;" +
        "margin-top: " +
        this.component.logo.marginTop +
        "px ;" +
        "margin-left: " +
        this.component.logo.marginLeft +
        "px ;" +
        "margin-right: " +
        this.component.logo.marginRight +
        "px ;";
      return val;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    height() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + "px";
      } else {
        return this.component.heightValue.vh + "vh";
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + this.component.overlap.px + "px";
      } else {
        return this.component.heightValue.vh + this.component.overlap.vh + "vh";
      }
    }
    // image() {
    //   if (this.component.settings.mode === 'color') {
    //     return ''
    //   } else {
    //     return this.component.settings.image
    //   }
    // }
  },
  methods: {
    async handleSignUp() {
      const result = await this.$refs.contact.validate();
      if (!result) {
        return;
      }
      this.$store.dispatch("setAuthLoading", true);

      const payload = {
        firstname: this.firstname,
        lastname: this.lastname,
        color: this.color || this.getRandomColor(),
        fullnameUppercase: (this.firstname + this.lastname)
          .toUpperCase()
          .trim(),
        email: this.email.toLowerCase().trim(),
        image: this.image || {
          name: "",
          updated: "",
          smallUrl: "",
          mediumUrl: "",
          largeUrl: "",
          smallStoragePath: "",
          mediumStoragePath: "",
          largeStoragePath: ""
        },
        mobile: this.mobile,
        phone: this.phone,
        accountKey: this.memberDataKey || "",
        tags: [],
        companies: this.selectedCompanies,
        newCompanyObj: this.newCompanyObj
      };

      this.$store
        .dispatch("signUserUpFirebase", payload)
        .then(() => {
          return;
        })
        .catch(e => {
          console.log(e);
          this.$store.dispatch("setAuthLoading", false);
        });
    },
    async nextStep() {
      if (this.stage === 1) {
        this.validContact = await this.$refs.contact.validate();
        if (!this.validContact) {
          console.log("contact is invalid");
          return;
        }
      }

      if (
        this.stage === 2 &&
        this.step.company === 2 &&
        this.companyAtRegistrationRequired
      ) {
        this.validCompany = await this.$refs.company.validate();
        if (!this.validCompany) {
          console.log("company is invalid");
          return;
        }
      }

      if (
        this.stage === 3 &&
        (this.step.photo === 2 || this.step.photo === 3)
      ) {
        this.validPhoto = await this.$refs.photo.validate();
        if (!this.validPhoto) {
          console.log("photo id is invalid");
          return;
        }
      }

      this.stage++;
      return;
    },
    backStep() {
      this.stage--;
      this.$store.dispatch("setRegistrationErrors", []);
    },
    handleAddNewCompany() {
      this.addNewCompany = true;
      this.selectedCompanyTag = "";
      this.selectedCompanies = [];
      this.$validator.reset();
    },
    handleCloseAddNewCompany() {
      this.addNewCompany = false;
      this.newCompanyObj = {};
      this.$validator.reset();
    },
    getCompanyName(ids) {
      if (ids === null) {
        return ["No Company Selected"];
      } else if (ids.length > 0 && !this.addCompanyAtRegistration) {
        var result = [];
        if (this.multipleCompaniesAtRegistration) {
          ids.forEach(val => {
            result.push(
              this.$store.state.company.companies.find(el => el.id === val)
                .title
            );
          });
        } else {
          result.push(
            this.$store.state.company.companies.find(el => el.id === ids).title
          );
        }
        return result;
      } else if (ids.length > 0 && this.addCompanyAtRegistration) {
        return [this.newCompanyObj.title];
      }
      return ["No Company Selected"];
    },
    clearSelectedCompany() {
      console.log(this.selectedCompanies);
      this.selectedCompanies = [];
      console.log(this.selectedCompanies);
    },
    getRandomColor() {
      const colors = AVATAR_COLORS;
      const random = Math.floor(Math.random() * colors.length);
      const result = colors[random];
      return result;
    },
    handleSignin() {
      //console.log('Sign in selected')
      this.$router.push("/signin");
    },
    handleTermsDialog() {
      this.openTermsDialog = true;
    },
    onCloseTerms() {
      this.openTermsDialog = false;
    }
  },
  watch: {
    currentUser(value) {
      if (value) {
        this.$store.dispatch("loadTranslationData");
        this.$store.dispatch("getPrimaryMenus", this.currentUser);
        this.$store.dispatch("getSecondaryMenus", this.currentUser);
        this.$store.dispatch("getUserDealer", this.currentUser);
        this.$store.dispatch("getProgressData", this.currentUser);
        this.$router.push("/Home");
        this.$store.dispatch("getPromotionList", this.currentUser);
        this.$store.dispatch("getRewardList", this.currentUser);
        this.$store.dispatch("getOfferList", this.currentUser);
      }
    }
  }
};
</script>

<style scoped>
.theme-page {
  padding: 0px 10px 0px 0px !important;
}
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  left: 290px;
  margin-bottom: 10px;
  z-index: 1000 !important;
}
.stepper-style {
  background-color: transparent !important;
}
</style>
